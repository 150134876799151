import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueLazyload from '@jambonn/vue-lazyload'
import 'swiper/css'
import 'aos/dist/aos.css'
import '@/assets/js/bootstrap.bundle.min.js'
import '@/assets/sass/plugins.scss'
import '@/assets/sass/style.scss'
import i18n from './i18n'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'

library.add(faLocationArrow)
createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(i18n).use(store).use(router).use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1
}).mount('#app')
