export default {
  "website_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدغيلبي والطلحي"])},
  "more_about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد عنا"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "follow_on_socials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط التواصل"])},
  "useful_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط مفيدة"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])},
  "blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المقالات"])},
  "our_practice_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتنا المتنوعة"])},
  "more_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد من الخدمات"])},
  "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرفة المزيد"])},
  "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق العمل"])},
  "meet_our_advisors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق العمل"])},
  "our_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقالاتنا"])},
  "more_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد من المقالات"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
  "how_we_can_help_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف يمكننا مساعدتك"])},
  "message_sent_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم استلام رسالتك بنجاح وسيتم التواصل معك في أقرب وقت، شكرا لك"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاتف"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
  "marwan_tech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تصميم وبرمجة مروان تك"])},
  "wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ 404"])},
  "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم إيجاد الصفحة"])},
  "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري الإرسال"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فارغ"])},
  "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد نتائج"])},
  "recapcha_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتحديد إنك لست روبوتا"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
  "no_data_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد بيانات"])},
  "controle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحكم"])},
  "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ المتغيرات"])},
  "all_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع المقالات"])},
  "all_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الخدمات"])},
  "AboutView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "BlogsView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المقالات"])},
  "SingleBlogView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحة المقال"])},
  "ServicesView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])},
  "SingleServiceView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحة الخدمة"])},
  "TeamView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق العمل"])},
  "ContactView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
  "LocationView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع"])},
  "Error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحة غير موجودة"])},
  "law_consultation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استشارة قانونية"])},
  "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات التوثيق"])},
  "law_firm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات المحاماة"])},
  "DocumentationServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات التوثيق"])},
  "LawfirmServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات المحاماة"])},
  "copyrights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة"])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}